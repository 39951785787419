import { useEffect, useState } from "react";
import { notify } from "@utils/toast.utils";
import { cardsService } from "@services/cards";
import { cardService } from "@services/card";
import useTranslation from "next-translate/useTranslation";
import { ICard } from "@interfaces/card.interface";
import _findIndex from "lodash/findIndex";
import { useAuth } from "./useAuth";

export const useCards = (): any => {
  const { t } = useTranslation("common");
  const [cards, setCards] = useState<any[]>([]);
  const [card, selectCard] = useState<any>(null);
  const { user } = useAuth();

  const refreshActive = async () => {
    try {
      if (user) {
        const { data } = await cardService.active();
        selectCard(data);
      }
    } catch (err:any) {
      console.warn("useCards.refreshActive ::", err?.message)
    }
  };

  useEffect(() => {
    refreshActive();
    // eslint-disable-next-line
  }, [user]);

  const addCard = async (cardData: any) => {
    try {
      const { data } = await cardService.createStripe(cardData);
      setCards([...cards, data]);
      selectCard(null);
      await refreshActive();
      return notify("warning", t("messages.cards.createdCard"));
    } catch (err) {
      return notify("warning", t("messages.cards.cantAddCard"));
    }
  };

  const removeCard = async (cardId: string) => {
    try {
      await cardsService.remove(cardId);
      const newCards = [...cards];
      const cardIndex = _findIndex(cards, { id: cardId });
      if (cardIndex !== -1) {
        newCards.splice(cardIndex, 1);
        setCards(newCards);
      }
      return notify("warning", t("messages.cards.deletedCard"));
    } catch (err) {
      return notify("warning", t("messages.cards.cantDeleteCard"));
    }
  };

  const removeCardById = async (cardId: string) => {
    let notificationText = t("messages.cards.deletedCard");
    try {
      await cardsService.deleteById(cardId);
      const newCards = [...cards];
      const cardIndex = _findIndex(cards, { id: cardId });
      if (cardIndex !== -1) {
        newCards.splice(cardIndex, 1);
        setCards(newCards);
      }
      selectCard(null);
    } catch (err) {
      notificationText = t("messages.cards.cantDeleteCard");
    }
    notify("warning", notificationText);
  };

  const updateCard = async (cardId: string, cardData: ICard) => {
    await removeCard(cardId);
    await addCard(cardData);
  };

  return [
    { card, cards },
    { selectCard, addCard, removeCard, updateCard, refreshActive, removeCardById },
  ];
};
