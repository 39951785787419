import { ICard } from "@interfaces/card.interface";
import { HttpService } from "@services/base";

class CardsService extends HttpService {
  private readonly prefix: string = "v2/card";
  findAll = (): Promise<any> => this.get(`${this.prefix}`);

  findOne = (id: string): Promise<any> => this.get(`${this.prefix}/${id}`);

  create = (body: ICard): Promise<any> => this.post(`${this.prefix}`, body);

  remove = (id: string): Promise<any> => this.delete(`${this.prefix}/${id}`);

  deleteById = (id: string): Promise<any> => this.post(`${this.prefix}/remove/${id}`, {});
}

export const cardsService = new CardsService();
