import React from "react";
interface IProps {
  loading: boolean;
  label: string;
  disabled?: boolean;
  class?: string;
  type: "button" | "submit" | "reset" | undefined;
  onClick?: any;
  icon?: boolean;
}
export const CustomButton = ({
  loading,
  disabled,
  label,
  type,
  onClick: handle,
  class: classp,
  icon
}: IProps) => {
  return (
    <React.Fragment>
      {loading ? (
        <button type={type} className={classp ? classp : "at-btn"}>
          <i className="fas fa-circle-notch fa-spin"></i>
        </button>
      ) : (
        <button
          type={type}
          disabled={disabled}
          onClick={handle}
          className={classp ? classp : "at-btn"}
        >
          {label}
          {icon && <>&nbsp; <i className="icon-tick ml-3"/></>}
        </button>
      )}
      <style jsx>{`
        .button {
          height: 40px;
          padding: 0px 20px;
          border-radius: 8px;
          font-family: var(--primary-font);
          flex: inherit;
        }
      `}</style>
    </React.Fragment>
  );
};
