import { useState, useCallback, useEffect } from "react";
import { notify } from "@utils/toast.utils";
import { paymentsSubscriptionService } from "@services/payments-subscription";

export const useSubscription = (): any => {
  const [subscribed, setSubscribed] = useState<any>(null);
  const [subDetails, setSubDetails] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [subscriptions] = useState<any>([]);

  const unSubscribe = async (channelId: string, userId: string) => {
    try {
      const {data} = await paymentsSubscriptionService.findAll();
      const sub = data.find((sub: any) => (sub.channel === channelId && sub.user === userId && sub.active === true));
      if (!sub) {
        notify("error", "No estás suscrito en este canal");
        setSubscribed(false);
        return;
      }
      if (!sub.active) {
        let date = new Date(sub.endingDate).toLocaleDateString('es-ES')
        notify("info", "Tu suscripción se renovará el " + date);
        setSubscribed(true);
        return;
      }
      await paymentsSubscriptionService.remove(sub.id);
      notify("warning", "Te has desuscrito del canal");
      setSubscribed(false);
    } catch (err) {
      setError(err)
      // notify("error", "Ocurrió un error", {theme: 'colored'});
    }
  };

  const subscribe = async (channelId: string, room: string) => {
    try {
      await paymentsSubscriptionService.create({channel: channelId, room: room});
      notify("success", "Te has suscrito al canal", {theme: 'colored'});
      setSubscribed(true);
      window.location.reload();
    } catch (err) {
      setError(err)
      // notify("error", "Ocurrió un error", {theme: 'colored'});
    }
  };

  const getChannelSubscription = useCallback(async (channelId: string, userId: string) => {
    try {
      const { data } = await paymentsSubscriptionService.findAll();
      const activeSubs = await data?.filter((sub: any) => sub.active);
      if (activeSubs.length > 0) {
        setSubscribed(true);
        setSubDetails(activeSubs); // Set all active subscriptions
      } else {
        setSubscribed(false);
        setSubDetails([]); // Ensure subDetails is set to an empty array when there's no subscription
      }
    } catch (err) {
      setError(err);
      // notify("error", "Ocurrió un error", {theme: 'colored'});
    }
  }, []);

  
  useEffect(() => {
    if (error) {
      console.warn("useSubscription ::", error?.message)
    }
  },[error])

  return [
    { subscriptions, subscribed, subDetails },
    { subscribe, unSubscribe, getChannelSubscription },
  ];
};
