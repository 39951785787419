import { HttpService } from "@services/base";

class CardService extends HttpService {
  private readonly prefix: string = "v2/card";

  createStripe = (body: any): Promise<any> =>
    this.post(`${this.prefix}/stripeCard`, body);

  active = (): Promise<any> => this.get(`${this.prefix}/active`);
}

export const cardService = new CardService();
