import React, { useState } from "react";
import { CustomButton } from "../custom-components/customButtons";
import useTranslation from "next-translate/useTranslation";

export const SimpleModal = (props: any) => {
  const { t } = useTranslation("common");
  const [modal, setModal] = useState<boolean>(props.default);
  const [isLoad] = useState<boolean>(false);

  const toggle = () => {
    setModal(!modal);
    props.toggle();
  };

  return (
    <div id="stardust-modal" className="modal" onClick={() => toggle()}>
      <div className="modal-content">
        <div className="modal-header">
          <h2>{props.title ?? "Add subscription"}</h2>
        </div>
        <div className="modal-body">
          <div className="close" onClick={() => toggle()}>
            <i className="fas fa-times"></i>
          </div>
          <div className="at-modalcontentarea">
            <form className="at-themefrome">
              <div className="at-text">{props.text}</div>
              <div className="button-container">
                <CustomButton
                  label={t("subscribeModal.yes")}
                  loading={isLoad}
                  onClick={async () => {
                    await props.onClick({ bool: true, data: props.data });
                    props.toggle();
                  }}
                  type="button"
                  class="button primary"
                />

                <CustomButton
                  label={t("subscribeModal.no")}
                  loading={isLoad}
                  onClick={async () => {
                    props.toggle();
                  }}
                  type="button"
                  class="button primary"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <style jsx>{`
        .button-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
        }

        .modal-header {
          padding: 0px 15px;

          h2 {
            font-weight: 600;
            font-family: var(--primary-font);
            font-size: 17px;
          }
        }

        .at-text {
          font-size: 14px;
          font-family: var(--primary-font);
          font-weight: 400;
          margin-bottom: 20px;
        }

        .modal {
          display: flex;
          position: fixed;
          z-index: 10;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: auto;
          background-color: rgb(0, 0, 0);
          background-color: rgba(0, 0, 0, 0.4);
        }

        .modal-body {
          padding: 2px 16px;
          position: relative;
          padding-top: 15px;

          .close {
            position: absolute;
            right: 7px;
            top: -33px;
            font-size: 14px;
            color: #a5ddfe;
            cursor: pointer;
          }
        }

        .modal-footer {
          padding: 2px 16px;
          background-color: #5cb85c;
          color: white;
        }

        .modal-content {
          position: relative;
          background-color: #0E1121;
          margin: auto;
          padding: 0;
          border-radius: 8px;
          width: 80%;
          max-width: 400px;
          padding: 25px 10px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
          animation-name: animatetop;
          animation-duration: 0.4s;

          .at-themeimage {
            margin: 0 auto;
            width: 100%;
            height: 60px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: var(--primary-font);
            font-weight: 700;
            background: linear-gradient(45deg, #d7c9ff, #9cdcfc);
            -webkit-font-smoothing: antialiased;
            margin-bottom: 20px;

            span {
              font-size: 1.8rem;
            }
          }

          .grid {
            display: grid;
            margin-bottom: 10px;

            &.col-1 {
              grid-template-columns: 1fr;
            }

            &.col-2 {
              grid-template-columns: 1fr 1fr;
              gap: 10px;
            }
          }
        }

        @keyframes animatetop {
          from {
            top: -300px;
            opacity: 0;
          }
          to {
            top: 0;
            opacity: 1;
          }
        }
        .button {
          border-radius: 8px;
          -webkit-font-smoothing: antialiased;
          font-weight: 700;
          font-size: 16px;
          letter-spacing: 0.2px;
          margin: 0 auto;
          display: block;
        }
      `}</style>
    </div>
  );
};
