import { HttpService } from "@services/base";

interface ISuscription {
  channel: string;
  room?: string;
}

class PaymentsSubscription extends HttpService {
  private readonly prefix: string = "v2/payments-subscription";

  findAll = (): Promise<any> => this.get(`${this.prefix}`);

  findOne = (subscriptionId: string): Promise<any> =>
    this.get(`${this.prefix}/${subscriptionId}`);

  checkIsSubscribed = (channelId: string): Promise<any> =>
    this.get(`${this.prefix}/channel/${channelId}`);

  create = (body: ISuscription): Promise<any> =>
    this.post(`${this.prefix}`, body);

  remove = (subscriptionId: string): Promise<any> =>
    this.delete(`${this.prefix}/${subscriptionId}`);
}

export const paymentsSubscriptionService = new PaymentsSubscription();
